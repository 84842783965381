import React from 'react';
import {
    Grid,
    Link
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class Show extends React.Component {

    constructor(props) {
        super(props);
    }
 
    render() {
        const headerStyle = {
            height: "187px",
            background: "#292E49",
            opacity: 1,
            color: "white",
            textAlign:"center"
        }

        return (
            <>
                <div className="max-width-lg padding-y-lg">
                    <div className="margin-bottom-lg" style={headerStyle}>
                        <div style={{ textAlign: "center", paddingTop: "15px" }}>
                            <p>Financial Fitness</p>
                            <h3 className="margin-bottom-xxs" >
                                <h2>{this.props.financial_fitness_video.title}</h2>
                            </h3>
                        </div>
                    </div>                            
                </div>
                <Grid container justify="center">
                    <Grid item md={8} xs={12} style={{margin: "10px 0 10px 0"}}>
                        <iframe src={this.props.financial_fitness_video.url} width="100%" height="450px" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        <Link href="/financial_fitness_videos"><ArrowBackIcon /> Back To Financial Fitness</Link>
                    </Grid>
                </Grid>
            </>

        )
    }
}
export default Show