import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slide"] // jshint ignore:line

  get slideshowLength() {
    return this.slideTargets.length - 1;
  }

  initialize() {
    this.showCurrentSlide();
  }

  next() {
    if(this.index >= this.slideshowLength) {
      this.index = 0
    } else {
      this.index++
    }
  }

  previous() {
    if(this.index === 0) {
      this.index = this.slideshowLength
    } else {
      this.index--
    }
  }

  showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", this.index === i)
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
    this.showCurrentSlide()
  }
}
