import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

class Index extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            data: "",
            start_date: this.lastMonthDate(),
            end_date: this.getCurrentDate(),
            fp_users: [],
            loadData: false,
            loadMessage: "loading data.."
        }
    }

    componentDidMount() {
        this.fetchCommissionData();
    }

    fetchCommissionData= () => {
        let url = `/admin_site/get_commission_data`
        fetch(url, {
            method: 'post',
            body: JSON.stringify({start_date: this.state.start_date, end_date: this.state.end_date}),
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                'accept': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if(data.result.success == true){
                this.setState({fp_users: data.result.resource, loadData: true})
            }else{
                this.setState({loadMessage: "Could not find the data", loadData: false})
            }
        }).catch((error) => {
            this.setState({loadMessage: "Error", loadData: false})
            console.log(error)
        })
    }

    formatDate(date){
        let day = ("0" + date.getDate()).slice(-2);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let year = date.getFullYear();
        return `${year}-${month}-${day}`
    }

    getCurrentDate() {
        let date = new Date();
        return this.formatDate(date);
    }
    

    lastMonthDate(){
        let todaysDate = new Date();
        let lastMonth = new Date(todaysDate);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return this.formatDate(lastMonth);
    }
    
    startDateChange = (event) => {
        this.setState({
            start_date: event.target.value
        })
    };

    endDateChange = (event) => {
        this.setState({
            end_date: event.target.value
        })
    };

    render(){
        let fp_users = this.state.fp_users.map((user) => {
            return (
                <TableRow key={user.id}>
                    <TableCell>{user.fp_name}</TableCell>
                    <TableCell>{user.user_renewals}</TableCell>
                    <TableCell>{user.fp_renewals}</TableCell>
                    <TableCell>${user.user_commission}</TableCell>
                    <TableCell>${user.fp_commission}</TableCell>
                </TableRow>
            )
        });
        const headerStyle = {
            minHeight: "187px",
            background: "#292E49", 
            opacity: 1,
            color: "white",
        }

        const dateSelectorStyle = {
            width: "100%",
            height: "44px",
            backgroundColor: "white",
            color: "black",
            borderRadius: "25px",
            padding: "10px",
        }

        const submitButtonStyle = {
            width: "100%",
            height: "44px",
            backgroundColor: "#61d262",
            borderRadius: "25px",
            textAlign: "center",
        } 

        const filterGridStyle = {
            marginBottom: "5px"
        }
        return(
            <>
                <div>
                    <section>
                        <div className="max-width-lg padding-y-lg">
                            <div className="margin-bottom-lg" style={headerStyle}>
                                <h3 className="margin-bottom-xxs" style={{textAlign: "center", paddingTop: "15px"}}>
                                    Commission Report
                                </h3>
                                <Grid container justify="center">
                                    <Grid item md={6} xs={12} style={{padding:"10px"}}>
                                        <Grid container spacing={1}>
                                            <Grid item md={3} xs={12} style={filterGridStyle}>
                                                <input
                                                    id="start_date"
                                                    name="start_date"
                                                    type="date"
                                                    ref="start_date"
                                                    defaultValue={this.state.start_date}
                                                    required
                                                    style={dateSelectorStyle}
                                                    onChange={this.startDateChange}
                                                />
                                            </Grid>
                                            <Grid item md={3} xs={12} style={filterGridStyle}>
                                                    <input
                                                        id="end_date"
                                                        name="end_date"
                                                        type="date"
                                                        ref="end_date"
                                                        defaultValue={this.state.end_date}
                                                        required
                                                        style={dateSelectorStyle}
                                                        onChange={this.endDateChange}
                                                    />
                                            </Grid>
                                            <Grid item md={3} xs={12} style={filterGridStyle}>
                                                <Button variant="contained" style={submitButtonStyle} href="#" onClick={this.fetchCommissionData} >
                                                    Generate Report
                                                </Button>
                                            </Grid>
                                            <Grid item md={3} xs={12} style={filterGridStyle}>
                                                <Button variant="contained"  style={submitButtonStyle} href={`/admin_site/download_commission_csv.csv?start_date=${this.state.start_date}&end_date=${this.state.end_date}`} >
                                                    Download Report
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                </Grid>
                                
                            </div> 
                            <Grid container justify="center" align="center">
                                <Grid item md={8} xs={12}>
                                    <div className="" style={{ marginTop:"10px", marginBottom: "10px"}}>
                                        {
                                            this.state.loadData ?
                                            <TableContainer justify="center" align="center">
                                                <Table style={{all:"initial", width: "100%"}}>
                                                    <TableHead>
                                                        <TableRow style={{ backgroundColor: "#272042" }}>
                                                            <TableCell style={{ maxWidth: "100%", color: "white" }}>Name of FP</TableCell>
                                                            <TableCell style={{ color: "white" }}>User Renewals</TableCell>
                                                            <TableCell style={{ color: "white" }}>Total Paid FP Accounts</TableCell>
                                                            <TableCell style={{ color: "white" }}>$ End User Commission </TableCell>
                                                            <TableCell style={{ color: "white" }}>$ FP Commission </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {fp_users}
                                                    </TableBody>    
                                                </Table>
                                            </TableContainer>
                                            :
                                            <p>
                                                {this.state.loadMessage}
                                            </p>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}
 export default Index