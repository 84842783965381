import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class FinancialProfessional extends React.Component{

    constructor(props){
        super(props);  
        this.state = {
            matches: window.matchMedia("(max-width: 960px)").matches
        }
    }
    
    render(){

        const leftColumn = {
            color: "black",
        }

        const leftColumnText ={
            padding: "20px",
            textAlign: "center"
        }

        const headerContainer = {
            padding: "20px",
            textAlign: "left",
            marginTop: this.state.matches && "10px"
        }

        const headerFont = {
            font: "normal normal bold 28px/38px Nunito Sans"
        }

        const imageContainer = {
            width: "100%",
            display: "flex",
            justifyContent: "center"

        }

        const circleImage = {
            height: "250px",
            width: "250px",
            borderRadius: "50%",
            backgroundImage: `url(${this.props.profile_image_url})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat'
        }

        const contentContainer = {
            backgroundColor: "#FFFFFF",
            borderRadius: "25px",
            boxShadow: "0px 2px 26px #0000001A",
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px"
        }

        const buttonStyle = {
            borderRadius: "25px", 
            color: "white", 
            backgroundColor: "#61D262", 
            margin: "5px"
        }

        const contactMeEmail = () => {
            let result = "";
            if(this.props.sp_information.contact_me_email && this.props.sp_information.contact_me_email != ""){
                result = this.props.sp_information.contact_me_email;
            } else {
                result = this.props.sp_user.email;
            }
            return result;
        }

        return(
            <>
                <Grid container justify="center"  style={{ paddingTop: "15px", paddingBottom: "15px", backgroundColor: "white"}}>
                    <Grid item md={11} xs={11} style={{ borderRadius: "25px 25px 25px 25px"}}>
                        <Grid container>
                            <Grid item md={3} xs={12} style={leftColumn}>
                                <div style={imageContainer}>
                                    <div style={circleImage}></div>
                                </div>
                                <div style={leftColumnText}>
                                    <h1 style={{marginBottom: "0px"}}>{this.props.sp_user?.name}</h1>
                                    <p>{this.props.fp_information?.professional_practice}</p>
                                    <p>License Number: {this.props.fp_information?.license_number}</p>
                                    <p>{this.props.sp_user?.address}<br/>
                                    {this.props.sp_user?.city} {this.props.sp_user?.state} {this.props.sp_user?.zip}<br/>
                                    {this.props.sp_user?.phone}</p>

                                    <div style={{textAlign:"center"}}>
                                        <Button href={`mailto:${contactMeEmail()}`} variant="contained" style={{ borderRadius: "25px", color: "white", backgroundColor: "#292E49"}} >Contact Me!</Button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={9} xs={12} style={headerContainer}>
                                <div style={contentContainer}>
                                    <div>
                                        <h1 style={headerFont} id="about-my-practice">About My Practice</h1>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.sp_information?.mission_statement}
                                        </p>
                                    </div>
                                </div>
                                <div style={contentContainer}>
                                    <div >
                                        <h1 style={headerFont} id="about-me">About Me</h1>
                                    </div>
                                    <div>
                                        <p>
                                            {this.props.sp_information?.bio}
                                        </p>
                                    </div>

                                </div>
                                 <div>
                                    <Button href={`/new_under_fp_user?user_id=${this.props.sp_user.id}`} variant="contained" style={buttonStyle} >Sign Up!</Button>
                                    <Button href={`/calculator?sp_user_id=${this.props.sp_user.id}`} variant="contained" style={buttonStyle} >Discover Your Debt Freedom Date</Button>
                                </div>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}
export default FinancialProfessional