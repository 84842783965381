import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class TellAFriend extends React.Component{
    
    render(){
        const fullFormField = {
            width: "100%",
            height: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px"
        }

        const buttonCss = {

            width: "120px",
            height: "40px",
            borderRadius: "20px",
            opacity: "1",
            marginBottom: "4px",
            marginTop: "4px", 
            backgroundColor: "#61d262"
        }
        return(
            <>
                    <Grid container justify="center" style={{ paddingTop: "90px", backgroundColor: "#272042",  paddingBottom: "30px"}}>
                        <Grid item md={4} xs={10} style={{ background: 'white', borderRadius: "25px 25px 25px 25px"}}>
                            <div className="dab-guy"><img src={this.props.image_url} alt="image"/></div>
                            <div style={{padding: "10px"}}>
                                <h2 style={{textAlign:"center"}}>Tell A Friend</h2>
                                <p>Designed exclusively for financial professionals, the MoneyAbility FP Ambassador program helps get your customers and clients out of debt faster all while building your professional network.</p>
                                <p>Refer a colleague to MoneyAbility. Once they sign up, you’ll get $50! </p>
                                <form action="/fp_site/send_tell_a_friend" method="post">
                                    <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                                    <div>
                                        <label htmlFor="name">Friend's Name:</label>
                                        <input
                                            style={fullFormField}
                                            label="name"
                                            name="name"
                                            type="text"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email">Friend's Email:</label>
                                        <input
                                            style={fullFormField}
                                            label="Email"
                                            name="email"
                                            type="email"
                                            required
                                        />
                                    </div>
                                    <div align="center">
                                        <Button variant="contained" type="submit" style={buttonCss}>
                                            Send
                                        </Button>
                                    </div>
                                    
                                </form>
                                <p><small>*Maximum of three free months plus any special Tell a Friend promotions referral enrollments per calendar year.</small></p>
                            </div>
                        </Grid>
                    </Grid>
            </>
        )
    }
}
 export default TellAFriend