import React from 'react'

import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

class Show extends React.Component{

    constructor(props){
        super(props);
    }

    formatDate(datetime){
        let date = new Date(datetime);
        return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    }
    
    render(){

        const headerStyle = {
            height: "187px",
            background: "#292E49", 
            opacity: 1,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
        return(
            <>
                <div className="max-width-lg padding-y-lg">
                    <div className="margin-bottom-lg" style={headerStyle}>
                        <h3 className="margin-bottom-xxs" style={{textAlign: "center", marginTop: "5px"}}>
                            Financial Professional User
                        </h3>
                    </div> 
                    <div style={{display: "flex", justifyContent: "flex-end", margin: "5px"}}>
                        { this.props.fp_user.active_subscription == false && 
                            <form action={`/admin_site/fp_users/${this.props.fp_user.id}`} method="post">
                                <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                                <input type="hidden" name="_method" value="patch" />
                                <Button variant="contained" type="submit" style={{ marginLeft: "4px", backgroundColor: "#61d262" }}>
                                    Approve Request
                                </Button>
                            </form>  
                        }  
                    </div> 
                    <Grid container justify="center">
                        <Grid item md={8} xs={11}>
                            <TableContainer style={{display: "flex", justifyContent: "center"}}>
                                <Table  aria-label="simple table" style={{all:"initial"}}>
                                    <TableBody>
                                        <TableRow >
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">{this.props.fp_user.name}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left" >{this.props.fp_user.email}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell align="left">Phone</TableCell>
                                            <TableCell align="left">{this.props.fp_user.phone}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell align="left">Address</TableCell>
                                            <TableCell align="left">{this.props.fp_user.full_address}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell align="left">Profession Practice</TableCell>
                                            <TableCell align="left">{this.props.fp_user.fp_user_information.professional_practice}</TableCell>
                                        </TableRow>
                                        {
                                            this.props.fp_user.fp_user_information.professional_practice == "Other" &&
                                            <TableRow >
                                                <TableCell align="left">Other Profession Practice</TableCell>
                                                <TableCell align="left">{this.props.fp_user.fp_user_information.other_professional_practice}</TableCell>
                                            </TableRow>
                                        }
                                        <TableRow >
                                            <TableCell align="left">Licensed</TableCell>
                                            <TableCell align="left">{this.props.fp_user.fp_user_information.licensed == true ? "Yes" : "No"}</TableCell>
                                        </TableRow>

                                        <TableRow >
                                            <TableCell align="left">Licensed States</TableCell>
                                            <TableCell align="left">{this.props.fp_user.fp_user_information.licensed_states.join(", ")}</TableCell>
                                        </TableRow>
                                        
                                        <TableRow >
                                            <TableCell align="left">Has SP Subscription</TableCell>
                                            <TableCell align="left">{this.props.fp_user.has_sp_sub ? "Yes" : "No"}</TableCell>
                                        </TableRow>

                                        <TableRow >
                                            <TableCell align="left">Request Status</TableCell>
                                            <TableCell align="left">{this.props.fp_user?.fp_user_subscription_request?.status}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}
 export default Show