import React from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import InputMask from 'react-input-mask'

class NewUnderFpUser extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            matches: window.matchMedia("(max-width: 960px)").matches
        }
    }

    render(){
        const rightPanelCss = {

            background: "#FFFFFF 0% 0 % no - repeat padding- box",
            borderRadius: this.state.matches ? "25px 25px 25px 25px" : "0 25px 25px 0",
            opacity: "1",
            background: "white",
            color: "black",
            padding: "10px",
            marginTop: this.state.matches && "10px"
        }

        const rightPanelHeader = {
            width: "100%",
            height: "32px",
            textAlign: "center",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: "1",
        }

        const formLableCss = {

            width: "200px",
            height: "20px",
            textAlign: "left",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: "0.75",
            padding: "10px"
        }

        const halfFormField = {
            width: "100%",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px",
            boxSizing: "border-box"

        }

        const fullFormField = {
            width: "100%",
            height: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px",
            boxSizing: "border-box"
        }

        const buttonCss = {
            maxWidth: "438px",
            height: "40px",
            background: "#35BA36 0% 0% no-repeat padding-box",
            borderRadius: "20px",
            opacity: "1"
        }

        const imageStyle = {
            width: "100%",
            borderRadius: this.state.matches ? "25px 25px 0 0" : "25px 0 0 0"
        }

        const rightColumnTextContent = {
            padding: "20px",
            textAlign: "left"
        }

        const leftColumn = {
            backgroundColor: "#7982B4",
            color: "white",
            borderRadius: this.state.matches ? "25px 25px 25px 25px" : " 25px 0 0 25px ",
            marginTop: this.state.matches && "10px"
        }
        return(
            <>
                <Grid container justify="center" style={{paddingTop: "20px",  backgroundColor: "#272042"}}>
                    <Grid item md={2} xs={10} style={leftColumn}>
                        <div style={{ width: "100%" }}>
                            <img style={imageStyle} src={this.props.profile_image_url} alt="image" />
                        </div>
                        <div style={rightColumnTextContent}>
                            <h1 style={{ marginBottom: "0px" }}>{this.props.sp_user?.name}</h1>
                            <p>{this.props.fp_information?.professional_practice}</p>
                            <p>{this.props.sp_user?.address}<br />
                                {this.props.sp_user?.city} {this.props.sp_user?.state} {this.props.sp_user?.zip}<br />
                                {this.props.sp_user?.phone}</p>

                            <div style={{ textAlign: "center" }}>
                                <Button href={`mailto:${this.props.sp_user?.email}`} variant="contained" style={{ borderRadius: "25px", color: "black" }} >Contact Me!</Button>
                            </div>

                        </div>
                    </Grid>

                    <Grid item md={4} xs={10} style={rightPanelCss}>
                        <Grid containerjustify="center" style={{textAlign:"center"}}>
                            <Grid item xs>
                                <h3 style={rightPanelHeader}>Get started</h3>
                            </Grid>
                        </Grid>
                        <form action="/create_under_fp_user" method="post">
                            <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                            <input type="hidden" name="user[fp_referred_by_id]" value={this.props.sp_user.id} />
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <label htmlFor="first_name" style={formLableCss}>First Name*</label>
                                    <input type="text"
                                        name="user[first_name]"
                                        required
                                        style={halfFormField}
                                        defaultValue={this.props.default_value?.first_name}   
                                    />
                                </Grid>
                                <Grid item md={6}xs={12}>
                                    <label htmlFor="last_name" style={formLableCss}>Last Name*</label>
                                    <input type="text"
                                        name="user[last_name]"
                                        required
                                        style={halfFormField}
                                        defaultValue={this.props.default_value?.last_name} 
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={6}xs={12}>
                                    <label htmlFor="email" style={formLableCss}>Email*</label>
                                    <input
                                        name="user[email]"
                                        required
                                        type="email"
                                        style={halfFormField}
                                        defaultValue={this.props.default_value?.email} 
                                    />
                                </Grid>
                                <Grid item md={6}xs={12}>
                                    <label htmlFor="phone_number" style={formLableCss}>Phone Number*</label> <br />
                                    <InputMask
                                        mask="999-999-9999"
                                        defaultValue={this.props.default_value?.phone} 
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => <input type="text"
                                            name="user[phone]"
                                            required
                                            style={halfFormField}
                                            
                                        />}
                                    </InputMask>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <label htmlFor="password" style={formLableCss}>Password*</label>
                                    <input
                                        name="user[password]"
                                        required
                                        type="password"
                                        style={fullFormField}                                            />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <label htmlFor="password" style={formLableCss}>Password Confirmation*</label>
                                    <input
                                        name="user[password]"
                                        required
                                        type="password"
                                        style={fullFormField}                                            />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} align="center">
                                    <Button variant="contained" type="submit" style={buttonCss}>
                                        <span style={{color:"white"}}>Get Started</span> 
                                    </Button> 
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </>
        )
    }
}
 export default NewUnderFpUser 