import React from "react";
import { Grid, Link } from "@material-ui/core";

class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const headerStyle = {
      height: "187px",
      background: "#292E49",
      opacity: 1,
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const videoCardCss = {
      border: "2px solid #ddd",
      padding: "5px",
      textAlign: "center",
      height: "100%"
    };
    const videos = this.props.financial_fitness_videos.map(video => (
      <Grid key={video.id} item md={4} xs={10}>
        <div style={videoCardCss}>
          <iframe
            src={video.url}
            width="100%"
            height="360px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
          <Link variant="h5" href={`/financial_fitness_videos/${video.id}`}>
            {video.title}
          </Link>
        </div>
      </Grid>
    ));

    return (
      <>
        <div className="max-width-lg padding-y-lg">
          <div className="margin-bottom-lg" style={headerStyle}>
            <h3
              className="margin-bottom-xxs"
              style={{ textAlign: "center", paddingTop: "15px" }}
            >
              Financial Fitness Library
            </h3>
          </div>
        </div>
        <Grid container justify="center" style={{ backgroundColor: "#292E49" }}>
          <Grid
            item
            md={8}
            xs={12}
            style={{ margin: "0 0 10px 0", color: "white" }}
          >
            <p style={{ marginTop: "-35px" }}>
              Our financial Fitness library of tutorials are designed to help
              simplify and unlock the power your money management tools. Your
              time is valuable, so we suggest beginning with the "Getting
              Started" video. After 4 or 5 minutes you will be ready to enroll
              accounts and create a budget with the simple push of a button!
            </p>

            <p>
              You can then take just a few minutes per tutorial to learn how to
              put these tools to work for you. The tutorials are set up in
              order. We suggest you begin with getting started and go left to
              right top to bottom to learn how to fully set up your own personal
              money management, automated solution.
            </p>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item md={8} xs={12} style={{ margin: "10px 0 10px 0" }}>
            <Grid container justify="center" spacing={1}>
              {videos}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default Index;
