import React from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';


class New extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            matches: window.matchMedia("(max-width: 960px)").matches
        }
    }

    render(){

        const leftPanelCss = {
            width: "448px",
            background: 'linear-gradient(to right, #3e435b, #7982b4)',
            borderRadius: this.state.matches ? "25px" : " 25px 0 0 25px ",
            padding: "10px",
            marginBottom: this.state.matches && "10px"
        }

        const leftPanelHeader = {
            height: "52px",
            textAlign: "left",
            font: "normal normal bold 49px/44px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1",
        }

        const leftPanelSubHeader = {
            height: "20px",
            textAlign: "left",
            font: "normal normal bold 15px/44px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1"
        }

        const leftPanelPara = {
            textAlign: "left",
            font: "normal normal 600 15px / 23px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "0.8"
        }

        const rightPanelCss = {
            background: "#FFFFFF 0% 0 % no - repeat padding- box",
            borderRadius: this.state.matches ? "25px" : " 0 25px 25px 0",
            opacity: "1",
            background: "white",
            color: "black", 
            padding: "10px"
        }

        const rightPanelHeader = {
            width: "100%",
            height: "32px",
            textAlign: "center",
            font: "normal normal bold 24px / 32px Nunito Sans",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: "1",
        }

        const formLableCss = {
            height: "20px",
            textAlign: "left",
            font: "normal normal 600 15px / 20px Nunito Sans",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: "0.75",
            padding: "10px"
        }

        const fullFormField = {
            width: "100%",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px"
        }

        const buttonCss = {
            maxWidth: "438px",
            height: "40px",
            background: "#35BA36 0% 0% no-repeat padding-box",
            borderRadius: "20px",
            opacity: "1"
        }

        return(
            <>
                <div className="fp-sign-up-page" style={{backgroundColor: "#272042", color: "white", paddingBottom: "30px"}}>
                    <Grid container justify="center" style={{paddingTop: "90px"}}>
                        <Grid item md={4} xs={10} style={leftPanelCss}>
                            <div className="dab-guy"><img src={this.props.image_url} alt="image"/></div>
                            <h2 style={leftPanelHeader}>Benefits</h2>
                            <p style={leftPanelSubHeader}>of a MoneyAbility Financial Professional</p> <br/> 
                            <div style={leftPanelPara}> 
                                <p>
                                    Happy clients refer. Make your new clients debt-free and happy, all while gaining new professionals connections and client referrals with MoneyAbility!
                                </p>
                                <ul>
                                    <li>
                                        Offer customers a platform that can help them pay off their debts in half the time
                                    </li>
                                    <li>
                                        Win trust by providing added value only MoneyAbility offers
                                    </li>
                                    <li>
                                        Help your colleagues help their own customers accelerate debt payoff
                                    </li>
                                    <li>
                                        Build your professional network and grow your list of clientele
                                    </li>
                                </ul>

                                <p>
                                    MoneyAbility Financial Professional is perfect for Real Estate Agents, Real Estate Brokers, Mortgage Loan Officers, Closers, Insurance Agents, Financial Advisors, Tax Planners, CPAs, Attorneys, and more!
                                </p>
                            </div>   
                        </Grid>
                        <Grid item md={4} xs={10} style={rightPanelCss}>
                            <Grid container justify="center" alignItems="center" style={{textAlign:"center"}}> 
                                <Grid item xs>
                                    <h3 style={rightPanelHeader}>Get started</h3>
                                </Grid>
                            </Grid>
                            <form action="/create_sp_information" method="post" encType="multipart/form-data">
                                <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                                <input type="hidden" name="sp_user_information[user_id]" value={this.props.user_id} /> 

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="status" style={formLableCss}>Upload Profile Picture *</label> <br/>
                                        <input type="file" name="sp_user_information[profile_image]" accept="image/png, image/jpeg"/>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="status" style={formLableCss}>Upload Logo *</label> <br/>
                                        <input type="file" name="sp_user_information[logo]" accept="image/png, image/jpeg"/>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="address" style={formLableCss}>About Your Practice *</label> <br/>
                                        <TextareaAutosize type="text"
                                            name="sp_user_information[mission_statement]"
                                            rowsMin="3"
                                            required
                                            style={fullFormField}
                                            defaultValue={this.props.default_value?.mission_statement} 
                                        />
                                    </Grid>
                                </Grid>                              
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="address" style={formLableCss}>About You *</label> <br/>
                                        <TextareaAutosize type="text"
                                            name="sp_user_information[bio]"
                                            rowsMin="3"
                                            required
                                            style={fullFormField}
                                            defaultValue={this.props.default_value?.bio} 
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} align="center">
                                        <Button variant="contained" type="submit" style={buttonCss}>
                                            <span style={{color:"white"}}>Get Started</span> 
                                        </Button> 
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}
 export default New