import React from 'react'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import StripeCheckout from 'react-stripe-checkout';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

class FpUserPlans extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            matches: window.matchMedia("(max-width: 960px)").matches
        }
    }

    onToken = (token, planIds) => {
        fetch('/fp_user_subscription_create', {
            method: 'POST',
            body: JSON.stringify({ token: token, planIds: planIds}),
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                accept: 'application/json'
            }
        }).then(response => {
            if (response.status == 200){
                console.log(response)
                window.location.href = "/thank_you"
            }else{
                alert(response.message)
            }   
        });
    }

    getCentAmount(dollar_amount){
        return parseInt(dollar_amount * 100)
    }

    render(){

        const leftPanelCss = {
            background: "transparent linear-gradient(270deg, #7982B4 0%, #3D415A 100%) 0% 0% no-repeat padding-box",
            borderRadius: this.state.matches ? "25px" : " 25px 0 0 25px ",
            opacity: "1",
            padding: "10px",
            marginBottom: this.state.matches && "10px"
        }

        const leftPanelHeader = {
            height: "52px",
            textAlign: "left",
            font: "normal normal bold 49px/44px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1",
        }

        const leftPanelSubHeader = {
            height: "20px",
            textAlign: "left",
            font: "normal normal bold 15px/44px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1"
        }

        const leftPanelPara = {
            textAlign: "left",
            font: "normal normal 600 15px / 23px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "0.8",
            padding: "10px"
        }

        const itemCardCss = {  
            background: "#FFFFFF",
            border: "2px solid #707070",
            borderRadius: "25px",
            opacity: "1",
        }

        const rightPanelCss = { background: "white",
            color: "black", 
            borderRadius: this.state.matches ? "25px" : "0 25px  25px  0",
            padding: "10px" 
        }

        let fp_plans = this.props.plans.map((value)=>{
            return(
                <Grid item lg={6} md={8} xs={12} key={value.id}>
                    <Card  style={itemCardCss}>
                        <CardContent >
                            <h3>{value.name}</h3>
                            <h6>${value.price}/ mo + ${this.props.one_time_fp_seat_payment.price} One Time</h6>
                            <hr className="shortest" />
                            <a href={value.stripe_url} target="_self">
                                <button> Purchase </button>
                            </a>
                        </CardContent>
                    </Card>
                </Grid>
            )
        })

        let gps_plans = this.props.plans.map((value)=>{
            return(
                <Grid item lg={6} md={8} xs={12} key={value.id}>
                    <Card  style={itemCardCss}>
                        <CardContent >
                            <h3>Guided Professional Services (GPS)</h3>
                            <h6>$395.95/ mo + ${this.props.one_time_fp_seat_payment.price} One Time</h6>
                            <hr className="shortest" />
                            <a href={value.stripe_url} target="_self">
                                <button> Purchase </button>
                            </a>
                        </CardContent>
                    </Card>
                </Grid>
            )
        })

        let fp_plans_with_financial_fitness = this.props.plans.map((value)=>{
            return(
                <Grid item lg={6} md={8} xs={12} key={value.id}>
                    <Card  style={itemCardCss}>
                        <CardContent >
                            <h3>{value.name} with Financial Fitness</h3>
                            <h6>${value.price}/ mo + ${this.props.one_time_fp_seat_payment.price} One Time +  ${this.props.financial_fitness_plan.price} One Time Financial Fitness</h6>
                            <hr className="shortest" />
                            <a href={this.props.financial_fitness_plan.stripe_url} target="_self">
                                <button> Purchase </button>
                            </a>                           
                        </CardContent>
                    </Card>
                </Grid>
            )
        })
        

        let sp_plans = this.props.sp_plans.map((value)=>{
            return(
                <Grid item lg={6} md={8} xs={12} key={value.id}>

                    <Card  style={itemCardCss}>
                        <CardContent >
                            <h3>{value.name}</h3>
                            <h6>${value.price}/ mo + ${this.props.one_time_sp_site_payment.price} One Time</h6>
                            <hr className="shortest" />
                            <a href={value.stripe_url} target="_self">
                                <button> Purchase </button>
                            </a>
                        </CardContent>
                    </Card>
                </Grid>
            )
        })
        return(
            <>
                <Grid container justify="center" style={{ backgroundColor: "#272042", color: "white", paddingBottom: "30px", padding: "10px", paddingTop: "90px"}}>
                    <Grid item md={4} xs={12} style={leftPanelCss}>
                        <div className="dab-guy"><img src={this.props.image_url} alt="image"/></div>
                        <h2 style={leftPanelHeader}>Benefits</h2>
                        <p style={leftPanelSubHeader}>of a MoneyAbility Financial Professional</p>
                        <div style={leftPanelPara}>
                            <br/>
                            <p>Complete the financial picture for new and existing clients solving for their most pressing need and question.  “How do I best manage and payoff my debt?”  Whether paying off student debt, buying or refinancing a home and owning if free and clear in about half the typical time, or supercharging a retirement plan, MoneyAbility is there for you and your client.</p>

                            <ul>
                                <li>Debt is front of mind, help new clients pay their debt off in about half the typical time with no loss to lifestyle.</li>
                                <li>Build trust up front and give prospective clients a business reason to choose you for professional services you provide</li>
                                <li>People will want to share the good news of debt freedom.  Our “Tell a Friend” link connects their referrals to you for help, and a flow of happy new clients for you.</li>
                            </ul>
                        </div>
                        
                    </Grid>
                    <Grid item md={4} xs={12} style={rightPanelCss}>
                        <Grid container spacing={3} justify="center"  style={{textAlign:"center"}}>
                            <Grid item xs>
                                <h3>Sign Up</h3>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" spacing={3} style={{ textAlign: "center"}}>
                            {gps_plans}
                            {fp_plans}
                            {fp_plans_with_financial_fitness}
                            {sp_plans}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}
 export default FpUserPlans