import React from 'react'
import Grid from '@material-ui/core/Grid';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Divider from '@material-ui/core/Divider';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import StripeCheckout from 'react-stripe-checkout';

class Index extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            last4: "",
            activeSubscription: this.props.user.active_subscription,
            expiryDate: ""
        }
    }

    componentDidMount() {
        this.fetchLast4();
        this.fetchExpiryDate();
    }

    onToken = (token, user_id) => {
        fetch('/update_card_information', {
            method: 'PATCH',
            body: JSON.stringify({ token: token, user_id: user_id }),
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                accept: 'application/json'
            }
        }).then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                alert(response.message)
            }
        }).then(data => {
            if (data.result.success == true) {
                this.setState({ last4: `************${data.result.resource}` })
            } else {
                this.setState({ last4: "Could not retrive." })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    cancelSubscription = () => {
        let url = `/cancel_subscription`;
        fetch(url, {
            method: 'PATCH',
            body: JSON.stringify({user_id: this.props.user.id}),
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                'accept': 'application/json'
            }
        }).then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                alert(response.message)
            }
        }).then(data => {
            if (data.result.success == true) {
                this.setState({ activeSubscription: false})
            } 
        }).catch((error) =>{
            console.log(error);
        })
    }

    fetchLast4 = () => {
        let url = `/get_last_4/${this.props.user.stripe_customer_id}`
        fetch(url, {
            method: 'get',
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                'accept': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if(data.result.success == true){
                this.setState({ last4: `************${data.result.resource}` })
            }else{
                this.setState({ last4: "Could not retrive." })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    fetchExpiryDate= () => {
        let url = `/get_plan_expires/${this.props.user.stripe_customer_id}`
        fetch(url, {
            method: 'get',
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                'accept': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if(data.result.success == true){
                this.setState({ expiryDate: `${data.result.resource}` })
            }else{
                this.setState({ expiryDate: "Could not retrive." })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    render(){
        const headerCss = {
            width: "100%",
            height: "150px",
            background: "#292E49",
            opacity: "1",
            textAlign: "center",
            display: "flex",
            alignItems: "center"
        }

        const headerTextCss = {
            width: "100%",
            height: "32px",
            font: "normal normal bold 24px / 32px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: 1
        }

        const planCardCss = {
            height: "215px",
            background: "#D0D3E5",
            borderRadius: "5px",
            opacity: 1,
            textAlign: "center"
        }

        const rightCardFont = {
            minHeight: "27px",
            textAlign: "left",
            font: "normal normal 600 20px / 27px Nunito Sans",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: 1,
            padding: "10px",
            overflowWrap: "break-word"
        }

        const updateLinkFont = {
            textAlign: "right",
            font: "normal normal 600 14px / 19px Nunito Sans",
            color: "#7982B4",
            opacity: 1
        }

        const cancelSubLink = {
            textAlign: "right",
            font: "normal normal 600 14px / 19px Nunito Sans",
            color: "#292E49",
            opacity: 1
        }

        let subscriptions = this.props.subscriptions.map((subscription) => {
            return(
                <TableRow key = { subscription.transaction_date } >
                    <TableCell>{subscription.transaction_date}</TableCell>
                    <TableCell>{subscription.plan}</TableCell>
                    <TableCell>{`************${subscription.last4}`}</TableCell>
                </TableRow >
)
        })
        return(
           
            <>
                <div style={headerCss}>
                    <h1 style={headerTextCss}>Subscription Management</h1>
                </div>
                <div>
                    <Grid container justify="center" style={{padding:"10px"}}>
                        <Grid item md={8} xs={12}>
                            <Grid container style={{marginTop:"20px"}}>
                                <Grid item md={6} xs={12}style={planCardCss}>
                                    <LocalAtmIcon fontSize="large"/>
                                    <h1>{this.props.plan}</h1>
                                </Grid>
                                <Grid item md={6} xs={12} style={rightCardFont}>
                                    {this.props.user.email}
                                    <Divider />
                                    
                                    <Grid container >
                                        <Grid item md={6} xs={12}><CreditCardIcon />{this.state.last4} </Grid>
                                        <Grid item md={6} xs={12} style={updateLinkFont}>
                                            <StripeCheckout
                                                token={token => this.onToken(token, this.props.user.id)}
                                                stripeKey={this.props.stripe_publishable_key}
                                                name={this.props.user.first_name}
                                                email={this.props.user.email}
                                                currency="USD"
                                                panelLabel="Update Card"
                                            >
                                                <a href="#">Update Card</a>
                                            </StripeCheckout>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    Plan Expires: {this.state.expiryDate}
                                    <Divider />

                                    { this.state.activeSubscription ?
                                        <>
                                            <Link href="#" style={cancelSubLink} onClick={() => {if(window.confirm('Are you sure?')){this.cancelSubscription()}}}>
                                                Cancel Plan
                                            </Link>
                                        </> 
                                        :
                                        <p>Plan Canceled</p>
                                    }
                                    <Divider />
                                </Grid>   
                            </Grid>
                            <Grid container justify="center" style={{ marginTop: "20px" }}>
                                <Table aria-label="customized table" style={{all:"initial"}}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#272042" }}>
                                            <TableCell style={{ color: "white" }}>Transaction Date</TableCell>
                                            <TableCell style={{ color: "white" }}>Plan</TableCell>
                                            <TableCell style={{ color: "white" }}>Payment Method</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subscriptions}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>       
            </>
        )
    }
}
 export default Index