import React from 'react'
import Grid from '@material-ui/core/Grid';

class FpUserPlans extends React.Component{

    constructor(props){
        super(props);  
    }
    
    render(){
        return(
            <>
                    <Grid container justify="center"  style={{ backgroundColor: "#272042", padding: "10px", paddingTop: "90px"}}>
                        <Grid item md={4} xs={12} style={{ background: 'white', textAlign:"center", borderRadius: "25px 25px 25px 25px"}}>
                            <div className="dab-guy"><img src={this.props.image_url} alt="image"/></div>
                            <h2>Thank You!</h2>
                            <p>You will hear from us soon.</p>
                        </Grid>
                    </Grid>
            </>
        )
    }
}
 export default FpUserPlans