import React from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import globals from "../utils/globals";
import Select from '@material-ui/core/Select';
import InputMask from 'react-input-mask'

class New extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            licenseState: props.default_value?.licensed_state || [],
            professionalPracticeOther: false,
            licensed: props.default_value?.licensed || false,
            professionalPractice: props.default_value?.professional_practice || "",
            phone: props.default_value?.phone_number || null,
            matches: window.matchMedia("(max-width: 960px)").matches
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleProfessionChange = this.handleProfessionChange.bind(this);

    }

    handleChange = (event) => {
        this.setState({
            licenseState: event.target.value
        })
    };

    handleProfessionChange = (event) => {
        this.setState({
            professionalPracticeOther: event.target.value == "Other",
            professionalPractice: event.target.value
        })
    };

    handleLicensedChange = (event) => {
        this.setState({
            licensed: event.target.checked
        })
    }
    
    render(){

        const leftPanelCss = {
            width: "448px",
            background: 'linear-gradient(to right, #3e435b, #7982b4)',
            borderRadius: this.state.matches ? "25px" : " 25px 0 0 25px ",
            padding: "10px"
        }

        const leftPanelHeader = {
            height: "52px",
            textAlign: "left",
            font: "normal normal bold 49px/44px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1",
        }

        const leftPanelSubHeader = {
            height: "20px",
            textAlign: "left",
            font: "normal normal bold 15px/44px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1"
        }

        const leftPanelPara = {
            textAlign: "left",
            font: "normal normal 600 15px / 23px Nunito Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "0.8"
        }

        const rightPanelCss = {
            
            background: "#FFFFFF 0% 0 % no - repeat padding- box",
            borderRadius: "20px",
            opacity: "1",
            background: "white",
            color: "black", 
            borderRadius: this.state.matches? "25px" : "0 25px 25px 0",
            padding: "10px",
            marginTop: this.state.matches && "10px"

        }

        const rightPanelHeader = {
            width: "100%",
            height: "32px",
            textAlign: "center",
            font: "normal normal bold 24px / 32px Nunito Sans",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: "1",
        }

        const formLableCss = {
            
            height: "20px",
            textAlign: "left",
            font: "normal normal 600 15px / 20px Nunito Sans",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: "0.75",
            padding: "10px"
        }

        const halfFormField = {
            width: "100%",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px"
        }

        const fullFormField = {
            width: "100%",
            height: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            paddingTop: "5px",
            paddingLeft: "10px"
        }

        const quarterFormField = {
            width: "100%",
            height: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px"
            
        }

        const quarterFormFieldState = {
            width: "100%",
            height: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            paddingLeft: "10px",
            paddingTop: "6px"
            
        }

        const buttonCss = {
            maxWidth: "438px",
            height: "40px",
            background: "#35BA36 0% 0% no-repeat padding-box",
            borderRadius: "20px",
            marginTop: "10px",
            opacity: "1"
        }

        const occupationOptions = ["Real Estate Agent", "Real Estate Broker", "Mortgage Loan Officer", "Closer", "Insurance Broker", "Financial Advisor", "Tax Planner", "CPA", "Branch Manager", "Attorney", "Other"].map((occupation) => {
            return (
                <MenuItem key={occupation} value={occupation}>
                    {occupation}
                </MenuItem>
            );
        });

        const stateOptions = globals.states.map((state) => {
            return (
                <MenuItem key={state} value={state}>
                    {state}
                </MenuItem>
            );
        });
        return(
            <>
                <div className="fp-sign-up-page" style={{backgroundColor: "#272042", color: "white", paddingBottom: "30px"}}>
                    <Grid container justify="center" style={{paddingTop: "90px"}}>
                        <Grid item md={4} xs={10} style={leftPanelCss}>
                            <div className="dab-guy"><img src={this.props.image_url} alt="image"/></div>
                            <h2 style={leftPanelHeader}>Benefits</h2>
                            <p style={leftPanelSubHeader}>of a MoneyAbility Financial Professional</p> <br/> 
                            <div style={leftPanelPara}> 
                                <p>
                                    Happy clients refer. Make your new clients debt-free and happy, all while gaining new professionals connections and client referrals with MoneyAbility!
                                </p>
                                <ul>
                                    <li>
                                        Offer customers a platform that can help them pay off their debts in half the time
                                    </li>
                                    <li>
                                        Win trust by providing added value only MoneyAbility offers
                                    </li>
                                    <li>
                                        Help your colleagues help their own customers accelerate debt payoff
                                    </li>
                                    <li>
                                        Build your professional network and grow your list of clientele
                                    </li>
                                </ul>

                                <p>
                                    MoneyAbility Financial Professional is perfect for Real Estate Agents, Real Estate Brokers, Mortgage Loan Officers, Closers, Insurance Agents, Financial Advisors, Tax Planners, CPAs, Attorneys, and more!
                                </p>
                            </div>
                            
                        </Grid>
                        <Grid item md={4} xs={10} style={rightPanelCss}>
                            <Grid container justify="center" alignItems="center" style={{textAlign:"center"}}>
                               
                                <Grid item xs>
                                    <h3 style={rightPanelHeader}>Get started</h3>
                                </Grid>
                                
                            </Grid>
                                <form action="/fp_users" method="post">
                                    <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                                    {this.props.fp_to_fp_referral_code && 
                                       <input type="hidden" name="fp_user[fp_to_fp_referral_code]" value={this.props.fp_to_fp_referral_code} /> 
                                    }
                                    <Grid container>
                                        <Grid item md={6} xs={12}>
                                            <label htmlFor="first_name" style={formLableCss}>First Name*</label>
                                            <input type="text"
                                                name="fp_user[first_name]"
                                                required
                                                style={halfFormField}  
                                                defaultValue={this.props.default_value?.first_name} 
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <label htmlFor="last_name" style={formLableCss}>Last Name*</label>
                                            <input type="text"
                                                name="fp_user[last_name]"
                                                required
                                                style={halfFormField}
                                                defaultValue={this.props.default_value?.last_name} 
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <label htmlFor="email" style={formLableCss}>Email* <br/>
                                                <em>Used for Primary/Personal account and site</em>
                                            </label>
                                            <input
                                                name="fp_user[email]"
                                                required
                                                type="email"
                                                style={halfFormField}
                                                defaultValue={this.props.default_value?.email} 
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={12} xs={12}>
                                            <label htmlFor="email" style={formLableCss}>Alternate Email*<br/>
                                                <em>Used for Demo/Sales site. Should not be same as main account</em>
                                            </label>
                                            <input
                                                name="fp_user[alternate_email]"
                                                required
                                                type="email"
                                                style={halfFormField}
                                                defaultValue={this.props.default_value?.email}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={6} xs={12}>
                                            <label htmlFor="password" style={formLableCss}>Password*</label>
                                            <input
                                                name="fp_user[password]"
                                                required
                                                type="password"
                                                style={halfFormField}
                                            />
                                        </Grid>
                                        <Grid item md={6}xs={12}>
                                            <label htmlFor="Confirm Password" style={formLableCss}>Confirm Password*</label>
                                            <input
                                                name="fp_user[confirm_password]"
                                                required
                                                type="password"
                                                style={halfFormField}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <label htmlFor="phone_number" style={formLableCss}>Mobile Phone*<br/>
                                            <span><em>Confirmation link will be sent to phone</em></span>
                                            </label> <br/>
                                            <InputMask
                                                mask="999-999-9999"
                                                value={this.state.phone}
                                                disabled={false}
                                                maskChar=" "
                                            >
                                                {() => <input type="text"
                                                    name="fp_user[phone_number]"
                                                    required
                                                    style={halfFormField}
                                                     />}
                                            </InputMask>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <label htmlFor="address" style={formLableCss}>Address*</label> <br/>
                                            <input type="text"
                                                name="fp_user[address]"
                                                required
                                                style={fullFormField}
                                                defaultValue={this.props.default_value?.address} 
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item md={4} xs={12}>
                                                    <label htmlFor="first_name" style={formLableCss}>City*</label>
                                                    <input
                                                        id="filled-full-width"
                                                        name="fp_user[city]"
                                                        required
                                                        style={quarterFormField}
                                                        defaultValue={this.props.default_value?.city} 
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <label htmlFor="state" style={formLableCss}>State*</label>
                                                    <TextField
                                                        InputProps={{disableUnderline: true}}
                                                        name="fp_user[state]"
                                                        defaultValue=""
                                                        required

                                                        style={quarterFormFieldState}
                                                        select
                                                        defaultValue={this.props.default_value?.state} 
                                                    >
                                                        {stateOptions}
                                                    </TextField>
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <label htmlFor="zip" style={formLableCss}>Zip*</label>
                                                    <input type="text"
                                                        name="fp_user[zip]"
                                                        required
                                                        style={quarterFormField}
                                                        defaultValue={this.props.default_value?.zip} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <label htmlFor="professional_practice" style={formLableCss}>Professional Practice*</label> <br/>
                                            <TextField
                                                name="fp_user[professional_practice]"
                                                placeholder="Professional Practice"
                                                value={this.state.professionalPractice}
                                                required
                                                onChange={this.handleProfessionChange}
                                                style={fullFormField}
                                                InputProps={{ disableUnderline: true }}
                                                select
                                            >
                                                {occupationOptions}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{display: `${this.state.professionalPracticeOther ? 'block': 'none'}`}}>
                                        <Grid item xs={12}>
                                            <label htmlFor="other_profession" style={formLableCss}>Other Profession</label> <br/>
                                            <input
                                                id="filled-full-width"
                                                name="fp_user[other_profession]"
                                                style={fullFormField}
                                                defaultValue={this.props.default_value?.other_profession} 
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Checkbox name="fp_user[licensed]" checked={this.state.licensed} value={this.state.licensed} onChange={this.handleLicensedChange}  inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /> Licensed
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <label htmlFor="professional_practice" style={formLableCss}>State(s) Licensed In <br/>
                                                <em>Can select multiple</em>
                                            </label> <br/>
                                            <Select
                                                id="fp_user[states_licensed_in]"
                                                name="fp_user[licensed_states]"
                                                multiple
                                                variant="outlined"
                                                style={fullFormField}
                                                value={this.state.licenseState}
                                                
                                                onChange={this.handleChange}

                                            >
                                                {stateOptions}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <label htmlFor="license_number" style={formLableCss}>License Number <br/>
                                            </label>
                                            <input
                                                name="fp_user[license_number]"
                                                style={halfFormField}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} align="center">
                                           <Button variant="contained" type="submit" style={buttonCss}>
                                                <span style={{color:"white"}}>Get Started</span> 
                                            </Button> 
                                        </Grid>
                                    </Grid>
                                </form>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}
 export default New