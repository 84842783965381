import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import PeopleIcon from '@material-ui/icons/People';

class Index extends React.Component{

    constructor(props){
        super(props);
    }

    formatDate(datetime){
        let date = new Date(datetime);
        return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    }

    showUserType(user){
        if (user.has_sp_sub === true){
            return(
                <TableCell>Sole Proprietor</TableCell>
            )
        } else if (user.fp_user && !user.has_sp_sub){
            return(
                <TableCell>Financial Professional</TableCell>
            )
        } else {
            return(
                <TableCell>End User</TableCell>
            )
        }
    }
    
    render(){
        let fp_referrees = this.props.fp_referrees.map((user) => {
            return (
                <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{this.formatDate(user.created_at)}</TableCell>
                    <TableCell>{user.active_subscription ? "Active": "Inactive"}</TableCell>
                    {this.showUserType(user)}
                </TableRow>
            )
        });

        const headerStyle = {
            height: "187px",
            background: "#292E49", 
            opacity: 1,
            color: "white",
            display: "flex",
            alignItems:"center",
            justifyContent: "center"
        }

        return(
            <>
                <div>
                    <section>
                        <div className="max-width-lg padding-y-lg">
                            <div className="margin-bottom-lg" style={headerStyle}>
                                <h3 className="margin-bottom-xxs" style={{textAlign: "center", paddingTop: "15px"}}>
                                    <PeopleIcon /> Subscribers
                                </h3>
                            </div> 
                            <div style={{marginTop:"10px", marginBottom: "10px"}}>
                                <Grid container justify="center">
                                    <Grid item md={8} xs={10}>
                                        {this.props.fp_referrees.length > 0 ? 
                                        <TableContainer justify="center" align="center">
                                                <Table style={{all:"initial", width: "100%"}}>
                                                <TableHead>
                                                    <TableRow style={{ backgroundColor: "#272042" }}>
                                                        <TableCell style={{ color: "white" }}>User Name</TableCell>
                                                        <TableCell style={{ color: "white" }}>Email</TableCell>
                                                        <TableCell style={{ color: "white" }}>Join Date</TableCell>
                                                        <TableCell style={{ color: "white" }}>Status</TableCell>
                                                        <TableCell style={{ color: "white" }}>Subscription Type</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {fp_referrees}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <p>No data.</p>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </section>
                </div>
            </>
            
        )
    }
}
 export default Index