import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

class Index extends React.Component{

    constructor(props){
        super(props);
    }

    changeUserStatus = (user) => {
        let url = `/admin_site/change_user_sp_status`
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({user_id: user.id}),
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                'accept': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            window.location.reload(true);
        }).catch((error) => {
            console.log(error)
        })
    }

    deleteUser = (user) => {
        let url = `/admin_site/delete_mx_user`
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({user_id: user.id}),
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {
                'X-CSRF-TOKEN': this.props.authenticity_token,
                'content-type': 'application/json',
                'accept': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            window.location.reload(true);
        }).catch((error) => {
            console.log(error)
        })
    }

    formatDate(datetime){
        let date = new Date(datetime);
        return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    }

    render(){
        let fp_users = this.props.fp_users.map((user) => {
            return (
                <TableRow key={user.id}>
                    <TableCell><Link href={`/admin_site/fp_users/${user.id}`}>{user.first_name} {user.last_name}</Link></TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{this.formatDate(user.created_at)}</TableCell>
                    <TableCell>{user.has_sp_sub ? "Yes" : "No"}</TableCell>
                    <TableCell>{user?.fp_user_subscription_request?.status}</TableCell>
                    <TableCell>
                        {user.suspended_user
                        ? <button onClick={() => this.changeUserStatus(user)}>Unsuspend</button>
                        : <button onClick={() => this.changeUserStatus(user)}>Suspend</button>
                        }
                    </TableCell>
                    <TableCell>
                        {user.active_status
                        ? <button onClick={() => this.deleteUser(user)}>Delete</button>
                        : <p>INACTIVE</p>
                        }
                    </TableCell> 
                </TableRow>
            )
        });

        const headerStyle = {
            height: "187px",
            background: "#292E49", 
            opacity: 1,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }

        const tableStyle = {
            tableLayout: "auto",
            width: "100%",
            overflowX: "auto"
        }

        return(
            <>
                <div>
                    <section>
                        <div className="max-width-lg padding-y-lg">
                            <div className="margin-bottom-lg" style={headerStyle}>
                                <h3 className="margin-bottom-xxs" style={{textAlign: "center", paddingTop: "15px"}}>
                                    Financial Professional Users
                                </h3>
                            </div> 
                            <div style={{marginTop:"10px", marginBottom: "10px"}}>
                                <Grid container justify="center">
                                    <Grid item md={8} xs={10}>
                                        <TableContainer component={Paper} >
                                            <Table style={tableStyle} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow style={{ backgroundColor: "#272042" }}>
                                                        <TableCell style={{ color: "white" }}>Name</TableCell>
                                                        <TableCell style={{ color: "white" }}>Email</TableCell>
                                                        <TableCell style={{ color: "white" }}>Access Requested Date</TableCell>
                                                        <TableCell style={{ color: "white" }}>SP Subscription Request</TableCell>
                                                        <TableCell style={{ color: "white" }}>Status</TableCell>
                                                        <TableCell style={{ color: "white" }}>Suspend User from Money Ability</TableCell>
                                                        <TableCell style={{ color: "white" }}>Delete MX Account</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {fp_users}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </section>
                </div>
            </>
            
        )
    }
}
 export default Index