import React from 'react'
import InputMask from 'react-input-mask'
import { 
    MenuItem, 
    Button, 
    Grid, 
    TextareaAutosize,
    TextField, 
    Checkbox, 
    Select
} from "@material-ui/core";
import globals from "../../utils/globals";

class EditSpInformation extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            licenseState: props.fp_user_information.licensed_states,
            professionalPracticeOther: props.fp_user_information.professional_practice == "Other",
            licensed: props.fp_user_information.licensed,
            professionalPractice: props.fp_user_information.professional_practice,
            phone: props.user.phone,
            license_number: props.fp_user_information.license_number,
            matches: window.matchMedia("(max-width: 960px)").matches,
            profile_image_attached: props.user.attachment,
            logo_attached: props.sp_user_information.attachment
        }
    }

    onPhoneChange = (event) => {
        this.setState({
            phone: event.target.value
        });
    }

    handleChange = (event) => {
        this.setState({
            licenseState: event.target.value
        })
    };

    handleProfessionChange = (event) => {
        this.setState({
            professionalPracticeOther: event.target.value == "Other",
            professionalPractice: event.target.value
        })
    };

    handleLicensedChange = (event) => {
        this.setState({
            licensed: event.target.checked
        })
    }

    handleLicenseNumberChange = (event) => {
        this.setState({
            license_number: event.target.value
        })
    }

    render(){
        const rightPanelCss = {
            background: "#FFFFFF 0% 0 % no - repeat padding- box",
            borderRadius:  "25px",
            opacity: "1",
            background: "white",
            color: "black", 
            padding: "10px"
        }

        const buttonCss = {
            maxWidth: "438px",
            height: "40px",
            background: "#35BA36 0% 0% no-repeat padding-box",
            borderRadius: "20px",
            opacity: "1"
        }

        const headerStyle = {
            height: "187px",
            background: "#292E49",
            opacity: 1,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }

        const formLableCss = {
            height: "20px",
            textAlign: "left",
            font: "normal normal 600 15px / 20px Nunito Sans",
            letterSpacing: "0px",
            color: "#292E49",
            opacity: "0.75",
            padding: "10px"
        }

        const halfFormField = {
            width: "100%",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px",
            color: "black",
            marginBottom: "1rem"
        }

        const fullFormField = {
            width: "100%",
            minHeight: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            paddingTop: "5px",
            paddingLeft: "10px"
        }

        const quarterFormField = {
            width: "100%",
            height: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            padding: "10px"
        }

        const quarterFormFieldState = {
            width: "100%",
            height: "44px",
            background: "#FFFFFF 0% 0 % no-repeat padding-box",
            border: "1px solid #CCCCCC",
            borderRadius: "22px",
            opacity: "1",
            paddingLeft: "10px",
            paddingTop: "6px"
        }

        const occupationOptions = ["Real Estate Agent", "Real Estate Broker", "Mortgage Loan Officer", "Closer", "Insurance Broker", "Financial Advisor", "Tax Planner", "CPA", "Attorney", "Other"].map((occupation) => {
            return (
                <MenuItem key={occupation} value={occupation}>
                    {occupation}
                </MenuItem>
            );
        });

        const stateOptions = globals.states.map((state) => {
            return (
                <MenuItem key={state} value={state}>
                    {state}
                </MenuItem>
            );
        });

        const defaultContactMe = () => {
            let result = "";
            if(this.props.sp_user_information.contact_me_email && this.props.sp_user_information.contact_me_email != ""){
                result = this.props.sp_user_information.contact_me_email;
            } else {
                result = ""
            }
            return result;
        }

        return(
            <>
                <div className="fp-sign-up-page" style={{paddingBottom: "30px"}}>
                    <div className="margin-bottom-lg" style={headerStyle}>
                        <h3 className="margin-bottom-xxs" style={{ textAlign: "center",}}>
                            Edit Landing Page
                        </h3>
                    </div>
                    <Grid container justify="center" style={{paddingTop: "20px"}}>
                       
                        <Grid item md={6} xs={10} style={rightPanelCss}>
                            <form action={`/fp_site/users/${this.props.user.id}`} method="post" encType="multipart/form-data">
                                <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                                <input type="hidden" name="sp_site[user_id]" value={this.props.user.id} /> 
                                <input type="hidden" name="_method" value="patch" />
                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={12}>
                                        <label htmlFor="first_name" style={formLableCss}>First Name *</label>
                                        <input type="text"
                                            name="sp_site[first_name]"
                                            required
                                            style={halfFormField}
                                            defaultValue={this.props.user?.first_name}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <label htmlFor="last_name" style={formLableCss}>Last Name *</label>
                                        <input type="text"
                                            name="sp_site[last_name]"
                                            required
                                            style={halfFormField}
                                            defaultValue={this.props.user?.last_name}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={12}>
                                        <label htmlFor="email" style={formLableCss}>Email*</label>
                                        <input
                                            disabled
                                            type="email"
                                            style={halfFormField}
                                            defaultValue={this.props.user?.email}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <label htmlFor="phone_number" style={formLableCss}>Phone Number* <br/>
                                            <em>Must be Mobile</em>
                                        </label> <br />
                                        <InputMask
                                            mask="999-999-9999"
                                            value={this.state.phone}
                                            disabled={false}
                                            onChange={this.onPhoneChange}
                                            maskChar=" "
                                        >
                                            {() => <input type="text"
                                                name="sp_site[phone]"
                                                required
                                                style={halfFormField}
                                            />}
                                        </InputMask>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="contact_me_email" style={formLableCss}>Alternate Email For Contact Me Button <em>Optional</em></label> <br />
                                        <input type="email"
                                            name="sp_site[contact_me_email]"
                                            style={fullFormField}
                                            defaultValue={defaultContactMe()}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="address" style={formLableCss}>Address*</label> <br />
                                        <input type="text"
                                            name="sp_site[address]"
                                            required
                                            style={fullFormField}
                                            defaultValue={this.props.user?.address}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item md={4} xs={12}>
                                                <label htmlFor="first_name" style={formLableCss}>City*</label>
                                                <input
                                                    id="filled-full-width"
                                                    name="sp_site[city]"
                                                    required
                                                    style={quarterFormField}
                                                    defaultValue={this.props.user?.city}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <label htmlFor="state" style={formLableCss}>State*</label>
                                                <TextField
                                                    InputProps={{ disableUnderline: true }}
                                                    name="sp_site[state]"
                                                    defaultValue=""
                                                    required

                                                    style={quarterFormFieldState}
                                                    select
                                                    defaultValue={this.props.user?.state}
                                                >
                                                    {stateOptions}
                                                </TextField>
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <label htmlFor="zip" style={formLableCss}>Zip*</label>
                                                <input type="text"
                                                    name="sp_site[zip]"
                                                    required
                                                    style={quarterFormField}
                                                    defaultValue={this.props.user?.zip}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="professional_practice" style={formLableCss}>Professional Practice*</label> <br />
                                        <TextField
                                            name="sp_site[professional_practice]"
                                            placeholder="Professional Practice"
                                            value={this.state.professionalPractice}
                                            required
                                            onChange={this.handleProfessionChange}
                                            style={fullFormField}
                                            InputProps={{ disableUnderline: true }}
                                            select
                                        >
                                            {occupationOptions}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} style={{ display: `${this.state.professionalPracticeOther ? 'block' : 'none'}` }}>
                                    <Grid item xs={12}>
                                        <label htmlFor="other_professional_practice" style={formLableCss}>Other Profession</label> <br />
                                        <input
                                            id="filled-full-width"
                                            name="sp_site[other_professional_practice]"
                                            style={fullFormField}
                                            defaultValue={this.props.fp_user_information?.other_profession}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Checkbox name="sp_site[licensed]" checked={this.state.licensed} value={this.state.licensed} onChange={this.handleLicensedChange} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /> Licensed
                                        </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="professional_practice" style={formLableCss}>State(s) Licensed In</label> <br />
                                        <Select
                                            id="sp_site[states_licensed_in]"
                                            name="sp_site[licensed_states]"
                                            multiple
                                            variant="outlined"
                                            style={fullFormField}
                                            value={this.state.licenseState}

                                            onChange={this.handleChange}

                                        >
                                            {stateOptions}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <label htmlFor="license_number" style={formLableCss}>License Number</label>
                                    <input type="text"
                                        name="sp_site[license_number]"
                                        style={halfFormField}
                                        defaultValue={this.props.sp_user_information?.license_number}
                                        onChange={this.state.handleLicenseNumberChange}
                                    />
                                </Grid>
                                <Grid container spacing={3}>
                                    {
                                        this.state.profile_image_attached ?
                                            <Grid item xs={12}>
                                                <div>
                                                    <img style={{maxHeight: "300px", maxWidth: "375px"}} src={this.props.user.profile_image_url} alt="User profile Image"/>
                                                </div>
                                                <label htmlFor="status" style={formLableCss}>Replace Profile Picture *</label> <br />
                                                <input type="file" name="sp_site[profile_image]" accept="image/png, image/jpeg" />
                                            </Grid>
                                        :
                                            <Grid item xs={12}>
                                                <label htmlFor="status" style={formLableCss}>Upload Profile Picture *</label> <br />
                                                <input type="file" name="sp_site[profile_image]" accept="image/png, image/jpeg" />
                                            </Grid>
                                    }
                                    
                                </Grid>

                                <Grid container spacing={3}>
                                    {
                                        this.state.logo_attached ?
                                            <Grid item xs={12}>
                                                <div>
                                                    <img style={{ maxHeight: "300px", maxWidth: "375px" }} src={this.props.sp_user_information.logo_url} alt="User profile Image" />
                                                </div>
                                                <label htmlFor="status" style={formLableCss}>Replace Logo *</label> <br />
                                                <input type="file" name="sp_site[logo]" accept="image/png, image/jpeg" />                                            
                                            </Grid>
                                            :
                                            <Grid item xs={12}>
                                                <label htmlFor="status" style={formLableCss}>Upload Logo *</label> <br />
                                                <input type="file" name="sp_site[logo]" accept="image/png, image/jpeg" />
                                            </Grid>
                                    }
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="address" style={formLableCss}>About Your Practice *</label> <br/>
                                        <TextareaAutosize type="text"
                                            name="sp_site[mission_statement]"
                                            rowsMin="3"
                                            required
                                            style={fullFormField}
                                            defaultValue={this.props.sp_user_information?.mission_statement} 
                                        />
                                    </Grid>
                                </Grid>                              
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <label htmlFor="address" style={formLableCss}>About You *</label> <br/>
                                        <TextareaAutosize type="text"
                                            name="sp_site[bio]"
                                            rowsMin="3"
                                            required
                                            style={fullFormField}
                                            defaultValue={this.props.sp_user_information?.bio} 
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} align="center">
                                        <Button variant="contained" type="submit" style={buttonCss}>
                                            <span style={{color:"white"}}>Save</span> 
                                        </Button> 
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}
 export default EditSpInformation