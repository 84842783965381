/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
/* eslint-disable prettier/prettier */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class Index extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            email: "",
        }
    }

    // eslint-disable-next-line class-methods-use-this
    render(){

        const headerStyle = {
            height: "187px",
            background: "#292E49", 
            opacity: 1,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }

        const buttonCss = {
            maxWidth: "438px",
            height: "40px",
            background: "#35BA36 0% 0% no-repeat padding-box",
            borderRadius: "20px",
            marginTop: "10px",
            opacity: "1"
        }

        return(
            <>
                <div>
                    <section>
                        <div className="max-width-lg padding-y-lg">
                            <div className="margin-bottom-lg" style={headerStyle}>
                                <h3 className="margin-bottom-xxs" style={{textAlign: "center", paddingTop: "15px"}}>
                                    Tools to delete user
                                </h3>
                            </div> 
                            <div style={{marginTop:"10px", marginBottom: "10px"}}>
                                <Grid container justify="center">
                                    <Grid item md={8} xs={10}>
                                            <Grid item md={12} xs={12}>
                                                <form action="/admin_site/user_destruction" method="post">
                                                    <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                                                    <label htmlFor="email" >Email to delete user*<br/>
                                                        <em>Email must exists</em>
                                                    </label>
                                                    <input
                                                        name="user[email]"
                                                        required
                                                        type="email"
                                                        defaultValue={this.state.email}
                                                    />
                                                    <Grid item xs={12} align="center">
                                                    <Button variant="contained" type="submit" style={buttonCss}>
                                                            <span style={{color:"white"}}>Get Started</span> 
                                                        </Button> 
                                                    </Grid>
                                                </form>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </section>
                </div>
            </>
            
        )
    }
}
 export default Index