import React from 'react'
import { Grid,
    Link
} from '@material-ui/core';

class Show extends React.Component{

    constructor(props){
        super(props);
    }

    
    render(){

        const headerStyle = {
            height: "187px",
            background: "#292E49", 
            opacity: 1,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }

        const gridStyle= {
            padding: "20px",
            textAlign: "center"
        }
        return(
            <>
               <div className="max-width-lg padding-y-lg">
                    <div className="margin-bottom-lg" style={headerStyle}>
                        <div style={{ textAlign: "center", paddingTop: "15px" }}>
                            <div className="margin-bottom-xxs" >
                                <h2>My Account</h2>
                            </div>
                                <p>Hello, {this.props.user.first_name}</p>
                        </div>
                    </div>                            
                </div>

                <Grid container justify="center">
                    <Grid item md={4} xs={12} style={gridStyle}>
                        <i className="fas fa-info-circle"></i>Settings &amp; Details
                    </Grid>
                    <Grid item md={4} xs={12} style={gridStyle}>
                        <div>
                            Email: {this.props.user.email}
                        </div>
                        <div>
                            Name: {this.props.user.name}
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12} style={gridStyle}>
                        <div>
                            <Link href={`/users/edit.${this.props.user.id}`}>Edit My Account</Link>
                        </div>
                        <div>
                            <Link href={`/subscriptions/${this.props.user.id}/edit`}>Manage My Subscription</Link>
                        </div>
                        <div>
                            <Link href={`/subscriptions/${this.props.user.id}`} data-method="delete" data-confirm="Are you sure?">Cancel My Subscription</Link>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }
}
 export default Show