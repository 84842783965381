import React from 'react'
import Grid from '@material-ui/core/Grid';

class FooterSpSite extends React.Component{

    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){

        const mainContainer = {
            height: "64px",
            background: "rgb(39, 32, 66)",
            color: "white",
            font: "Nunito Sans",
        }

        const headerImageContainer = {
            display: "flex",
            alignItems: "center"
        }

        const headerImg = {
            display: "block",
            height: "16px",
            width: "auto",
        }

        const poweredByColumn = {
            lineHeight: "0.9",
            marginTop: "14px",
            textAlign: "right", 
            paddingRight: "10px"
        }
        return(
            <>
                <Grid container justify="flex-end" style={mainContainer}>
                        
                    <Grid item md={3} xs={8}>
                        <Grid container >
                            <Grid item xs={4}>
                            <p style={poweredByColumn}> <br/> </p>
                            </Grid>
                            <Grid item xs={8} style={headerImageContainer} ><img src={this.props.image_url} style={headerImg} alt="image" /></Grid>
                        </Grid>
                       
                    </Grid>
                </Grid>
            </>
        )
    }
}
 export default FooterSpSite